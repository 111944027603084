import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import { getUserDetails } from '../apis/accountApi';
import { getLoginUrl } from '../apis/loginApi';
import mixpanelAnalytics from '../utils/analytics/providers/mixpanelAnalytics';
import { setSessionUrl } from '../redux/actions';
import { logErrorWithCustomMessage } from '../utils/sentry';

const accessTokenCookieName = 'access-token';

interface LoginContextValue {
    isLoggedIn: boolean;
    isLoading: boolean | null;
    accessToken?: string;
    login: (accessToken: string, expirySeconds: number) => void;
    logout: () => void;
    redirectToLogin: () => void;
    email: string | null;
    isTrade: boolean;
}

export interface LoginProviderProps {
    children: ReactNode;
}

const initialContextValue: LoginContextValue = {
    isLoggedIn: false,
    isLoading: null,
    email: null,
    accessToken: undefined,
    isTrade: false,
    login: () => {},
    logout: () => {},
    redirectToLogin: () => {},
};

export const LoginContext =
    createContext<LoginContextValue>(initialContextValue);

export function useLogin() {
    const context = useContext(LoginContext);
    if (!context) {
        throw new Error('No Login Context initialised.');
    }
    return context;
}

export function LoginProvider({ children }: LoginProviderProps) {
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [email, setEmail] = useState<string | null>(null);
    const [accessToken, setAccessToken] = useState<string | undefined>(
        Cookies.get(accessTokenCookieName)
    );
    const [isTrade, setIsTrade] = useState<boolean>(false);
    const location = useLocation();

    const mixpanel = useMemo(() => new mixpanelAnalytics(), []);

    useEffect(() => {
        if (accessToken !== undefined && isLoading == null && !isLoggedIn) {
            setIsLoading(true);
            getUserDetails(accessToken)
                .then(response => {
                    if (response.success && response.result !== null) {
                        setIsLoggedIn(true);
                        setIsTrade(response.result.isTrade);
                        setEmail(response.result.email);
                        mixpanel.loginUser(
                            response.result.email,
                            response.result.isTrade
                        );
                    } else {
                        setIsLoggedIn(false);
                        setEmail(null);
                        setIsTrade(false);
                        clearAccessCode();
                    }
                })
                .catch((e: Error) => {
                    logErrorWithCustomMessage(
                        e,
                        'Error logging in and getting user details.'
                    );
                    setIsLoggedIn(false);
                    setEmail(null);
                    setIsTrade(false);
                    clearAccessCode();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (accessToken === undefined && !isLoading && isLoggedIn) {
            setIsLoggedIn(false);
            setEmail(null);
            setIsTrade(false);
        }
    }, [accessToken, mixpanel, isLoading, isLoggedIn]);

    // Check every 10 seconds if the cookie access token has changed to keep them in sync
    useEffect(() => {
        const intervalId = setInterval(() => {
            const cookiedAccessToken = Cookies.get(accessTokenCookieName);
            if (accessToken !== cookiedAccessToken) {
                setAccessToken(cookiedAccessToken);
            }
        }, 10000);
        return () => clearInterval(intervalId);
    }, [accessToken]);

    useEffect(() => {
        if (!location.pathname.includes('login')) {
            setSessionUrl(location.pathname + location.search);
        }
    }, [location.pathname, location.search]);

    return (
        <LoginContext.Provider
            value={{
                isLoggedIn,
                isLoading: isLoading ?? true,
                email,
                login: handleLogin,
                logout: handleLogout,
                accessToken,
                redirectToLogin,
                isTrade,
            }}
        >
            {children}
        </LoginContext.Provider>
    );

    function handleLogin(accessToken: string, expirySeconds: number): void {
        var expiryTime = new Date(new Date().getTime() + expirySeconds);
        Cookies.set(accessTokenCookieName, accessToken, {
            expires: expiryTime,
            secure: true,
        });
        setAccessToken(accessToken);
    }

    function handleLogout(): void {
        clearAccessCode();
        mixpanel.logoutUser();
    }

    function clearAccessCode() {
        Cookies.remove(accessTokenCookieName);
        setAccessToken(undefined);
    }

    function redirectToLogin() {
        window.location.replace(getLoginUrl());
    }
}
