import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Action, Location, History } from 'history';

export type AppHistoryContextValue = {
    isFirstPage: boolean;
    pages: string[];
    history: History<unknown> | null;
};

export interface AppHistoryProviderProps {
    children: ReactNode;
}

const initialContextValue: AppHistoryContextValue = {
    isFirstPage: true,
    pages: [],
    history: null,
};

export const AppHistoryContext =
    createContext<AppHistoryContextValue>(initialContextValue);

export function useAppHistory() {
    const context = useContext(AppHistoryContext);
    if (!context) {
        throw new Error('No App History Context initialised.');
    }
    return context;
}

export function AppHistoryProvider({ children }: AppHistoryProviderProps) {
    const history = useHistory();
    const [pages, setPages] = useState<string[]>([history.location.pathname]);
    const isFirstPage = pages.length <= 1;

    useEffect(() => {
        function handleStateChange(location: Location, action: Action) {
            switch (action) {
                case 'POP':
                    setPages(p => p.slice(1));
                    break;
                case 'PUSH':
                    setPages(p => [location.pathname, ...p]);
                    break;
                case 'REPLACE':
                    setPages(p => [location.pathname, ...p.slice(1)]);
                    break;
            }
        }

        const unregister = history.listen(handleStateChange);
        return unregister;
    }, [history]);

    return (
        <AppHistoryContext.Provider value={{ isFirstPage, pages, history }}>
            {children}
        </AppHistoryContext.Provider>
    );
}
