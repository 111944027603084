import { Feature } from '@/endurance/featureFlags';

import {
    useState,
    useContext,
    createContext,
    useEffect,
    ReactNode,
} from 'react';
import { getSpecificFeatures } from '../apis/featureFlagApi';
import { isBot, isPreRender } from '../utils/config';
import { logErrorWithCustomMessage } from '../utils/sentry';
import { useLogin } from './LoginContext';
import { useExperimentTracking } from './ExperimentTrackingContext';
import { sendEventV2 } from '../utils/analytics/analyticsService';
import { Page, Area, InteractionItem, Action } from '../utils/analytics/enums';

export enum FeatureFlag {
    BlackFridayEnabled = 'site-blackfridayenabled',
    DealPageVersion = 'dealpage-version',
    TourPagePAndADisableFlights = 'tourpage-pandadisableflights',
    SearchResultsFilterOrdering = 'searchresults-orderfilters',
    SiteWideRemoveLinksBookingPaths = 'sitewide-removelinksbookingpaths',
    HomePageRemoveFlightSearchTab = 'homepage-removeflightstabsearch',
    HideWhoisGoingButton = 'tourpage-hideWhoIsGoingButton',
    TourEnquiryContactUsPopout = 'tourpage-enquirycontactuspopout',
    ContactUsRemoveHeader = 'contactus-removeheader',
    TourPagePAndAHoldForFree = 'tourpage-panda-holdforfree',
    TourSearchDatesOnResults = 'toursearch-datesonresults',
    SiteWideSearchToolDefaultSpecificDate = 'sitewide-searchtool-default-specificdate',
    LandingPagesLargerButtonToSRP = 'landingpages-largerbuttontosrp',
    SearchResultsResultImageMultipleImages = 'searchresults-resultimage-multipleimages',
    SearchResultsSmallDesktopDisplay = 'searchresults-small-desktop-display',
    SearchResultsSmallMobileDisplay = 'searchresults-small-mobile-display',
    LandingPagesReturnToCheckoutToast = 'landingpages-returntocheckouttoast',
    SoldOutDeparturesOnRequest = 'soldoutdepartures-onrequest',
    RemoveGuaranteedRefundUsp = 'guaranteedrefundusp-remove',
    TourPagePAndABookText = 'tourpage-pandabooktext',
    WhosGoingHideForm = 'tourpage-whosgoing-hideform',
    TourPageKeyDetailsV2 = 'tourpage-keydetailsv2',
    TourPagePandAAddFlightsButton = 'tourpage-panda-addflightsbutton',
    TourPageItineraryV2 = 'tourpage-itineraryv2',
    SiteWideEnableFlights = 'sitewide-enableflights',
    SiteWideEnableTradeBanner = 'sitewide-enableTradeBanner',
    SiteWideHeaderVersion = 'sitewide-headerversion',
    ImageHandlerVersion = 'sitewide-imagehandlerversion',
    TourPageImageGalleryVersion = 'tourpage-imagegallery-version',
}

export interface FeatureFlagContextValue {
    isLoading: boolean;
    features: Features | null;
}

export interface Features {
    blackFridayEnabled: boolean;
    dealPageVersion: 1 | 2;
    searchResultsOrderFilters: OrderingFeature;
    siteWideRemoveLinksBookingPaths: boolean;
    homePageRemoveFlightSearchTab: boolean;
    hideWhoisGoingButton: boolean;
    tourEnquiryContactUsPopout: boolean;
    contactUsRemoveHeader: boolean;
    tourPagePAndAHoldForFree: boolean;
    tourSearchDatesOnResults: boolean;
    siteWideSearchToolDefaultSpecificDate: boolean;
    landingPagesLargerButtonToSRP: boolean;
    searchResultsResultImageMultipleImages: boolean;
    searchResultsSmallDesktopDisplay: boolean;
    searchResultsSmallMobileDisplay: boolean;
    landingPagesReturnToCheckoutToast: boolean;
    soldOutDeparturesOnRequest: boolean;
    removeGuaranteedRefundUsp: boolean;
    tourPagePAndABookText: 0 | 1 | 2;
    whoIsGoingHideForm: boolean;
    tourPageKeyDetailsV2: boolean;
    tourPagePandAAddFlightsButton: boolean;
    tourPageItineraryV2: boolean;
    siteWideEnableFlights: boolean;
    siteWideEnableTradeBanner: boolean;
    siteWideHeaderVersion: 1 | 2;
    imageHandlerVersion: 1 | 2;
    TourPageImageGalleryVersion: 1 | 2;
}

export interface OrderingFeature {
    order: string[];
}

export interface FeatureFlagProviderProps {
    children: ReactNode;
}

const FeatureFlagContext = createContext<FeatureFlagContextValue | null>(null);

export function useFeatureFlag() {
    const context = useContext(FeatureFlagContext);
    if (!context) {
        throw new Error('No Feature Flag Context initialised.');
    }
    return context;
}

export function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
    const { accessToken } = useLogin();
    const [isLoading, setIsLoading] = useState(true);
    const [isRequesting, setIsRequesting] = useState(false);
    const [features, setFeatures] = useState<Features | null>(null);

    const experimentTrackingUUID =
        useExperimentTracking().experimentTrackingUUID;

    const bot = isPreRender() || isBot();

    useEffect(() => {
        if (!isRequesting && features === null && experimentTrackingUUID) {
            setIsRequesting(true);
            getSpecificFeatures(
                [
                    FeatureFlag.BlackFridayEnabled,
                    // TODO: Add these in as the pages are completed so we don't slow down the request
                    // FeatureFlag.DealPageVersion,
                    FeatureFlag.TourPagePAndADisableFlights,
                    FeatureFlag.SearchResultsFilterOrdering,
                    FeatureFlag.SiteWideRemoveLinksBookingPaths,
                    FeatureFlag.HomePageRemoveFlightSearchTab,
                    FeatureFlag.HideWhoisGoingButton,
                    FeatureFlag.TourEnquiryContactUsPopout,
                    FeatureFlag.ContactUsRemoveHeader,
                    FeatureFlag.TourPagePAndAHoldForFree,
                    FeatureFlag.TourSearchDatesOnResults,
                    FeatureFlag.SiteWideSearchToolDefaultSpecificDate,
                    FeatureFlag.LandingPagesLargerButtonToSRP,
                    FeatureFlag.SearchResultsResultImageMultipleImages,
                    FeatureFlag.SearchResultsSmallDesktopDisplay,
                    FeatureFlag.SearchResultsSmallMobileDisplay,
                    FeatureFlag.LandingPagesReturnToCheckoutToast,
                    FeatureFlag.SoldOutDeparturesOnRequest,
                    FeatureFlag.RemoveGuaranteedRefundUsp,
                    FeatureFlag.TourPagePAndABookText,
                    FeatureFlag.WhosGoingHideForm,
                    FeatureFlag.TourPageKeyDetailsV2,
                    FeatureFlag.TourPagePandAAddFlightsButton,
                    FeatureFlag.TourPageItineraryV2,
                    FeatureFlag.SiteWideEnableFlights,
                    FeatureFlag.SiteWideEnableTradeBanner,
                    FeatureFlag.SiteWideHeaderVersion,
                    FeatureFlag.ImageHandlerVersion,
                    FeatureFlag.TourPageImageGalleryVersion,
                ],
                experimentTrackingUUID,
                accessToken,
                bot
            )
                .then(response => {
                    if (response.isSuccess) {
                        const features: Features = {
                            blackFridayEnabled: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.BlackFridayEnabled,
                                false
                            ),
                            dealPageVersion: getFeatureValue<1 | 2>(
                                response.result,
                                FeatureFlag.DealPageVersion,
                                1
                            ),
                            searchResultsOrderFilters:
                                getFeatureValue<OrderingFeature>(
                                    response.result,
                                    FeatureFlag.SearchResultsFilterOrdering,
                                    {
                                        order: [
                                            'Age',
                                            'Budget',
                                            'Duration',
                                            'PhysicalRating',
                                            'TourType',
                                            'Destinations',
                                            'PrivateRoom',
                                            'ActivityGroup',
                                        ],
                                    }
                                ),
                            siteWideRemoveLinksBookingPaths:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SiteWideRemoveLinksBookingPaths,
                                    false
                                ),
                            homePageRemoveFlightSearchTab:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.HomePageRemoveFlightSearchTab,
                                    false
                                ),
                            hideWhoisGoingButton: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.HideWhoisGoingButton,
                                true
                            ),
                            tourEnquiryContactUsPopout:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.TourEnquiryContactUsPopout,
                                    false
                                ),
                            contactUsRemoveHeader: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.ContactUsRemoveHeader,
                                false
                            ),
                            tourPagePAndAHoldForFree: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourPagePAndAHoldForFree,
                                false
                            ),
                            siteWideSearchToolDefaultSpecificDate:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SiteWideSearchToolDefaultSpecificDate,
                                    false
                                ),
                            landingPagesLargerButtonToSRP:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.LandingPagesLargerButtonToSRP,
                                    false
                                ),
                            tourSearchDatesOnResults: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourSearchDatesOnResults,
                                false
                            ),
                            searchResultsResultImageMultipleImages:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultsResultImageMultipleImages,
                                    false
                                ),
                            searchResultsSmallDesktopDisplay:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultsSmallDesktopDisplay,
                                    false
                                ),
                            searchResultsSmallMobileDisplay:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SearchResultsSmallMobileDisplay,
                                    false
                                ),
                            landingPagesReturnToCheckoutToast:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.LandingPagesReturnToCheckoutToast,
                                    false
                                ),
                            soldOutDeparturesOnRequest:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.SoldOutDeparturesOnRequest,
                                    false
                                ),
                            removeGuaranteedRefundUsp: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.RemoveGuaranteedRefundUsp,
                                false
                            ),
                            tourPagePAndABookText: getFeatureValue<0 | 1 | 2>(
                                response.result,
                                FeatureFlag.TourPagePAndABookText,
                                0
                            ),
                            whoIsGoingHideForm: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.WhosGoingHideForm,
                                false
                            ),
                            tourPageKeyDetailsV2: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourPageKeyDetailsV2,
                                false
                            ),
                            tourPagePandAAddFlightsButton:
                                getFeatureValue<boolean>(
                                    response.result,
                                    FeatureFlag.TourPagePandAAddFlightsButton,
                                    false
                                ),
                            tourPageItineraryV2: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.TourPageItineraryV2,
                                false
                            ),
                            siteWideEnableFlights: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.SiteWideEnableFlights,
                                false
                            ),
                            siteWideEnableTradeBanner: getFeatureValue<boolean>(
                                response.result,
                                FeatureFlag.SiteWideEnableTradeBanner,
                                false
                            ),
                            siteWideHeaderVersion: getFeatureValue<1 | 2>(
                                response.result,
                                FeatureFlag.SiteWideHeaderVersion,
                                1
                            ),
                            imageHandlerVersion: getFeatureValue<1 | 2>(
                                response.result,
                                FeatureFlag.ImageHandlerVersion,
                                1
                            ),
                            TourPageImageGalleryVersion: getFeatureValue<1 | 2>(
                                response.result,
                                FeatureFlag.TourPageImageGalleryVersion,
                                1
                            ),
                        };

                        setFeatures(features);

                        sendEventV2(
                            Page.siteWide,
                            Area.siteWide,
                            InteractionItem.featureFlag,
                            Action.featureExposure,
                            {
                                experimentTrackingUUID: experimentTrackingUUID,
                                ...features,
                            }
                        );
                    } else {
                        throw new Error(
                            `Feature Flag request failed - ${response.errors}`
                        );
                    }
                })
                .catch(e => {
                    logErrorWithCustomMessage(e, 'Failed to get feature flags');
                    setFeatures({
                        blackFridayEnabled: false,
                        dealPageVersion: 1,
                        searchResultsOrderFilters: {
                            order: [
                                'Age',
                                'Budget',
                                'Duration',
                                'PhysicalRating',
                                'TourType',
                                'Destinations',
                                'PrivateRoom',
                                'ActivityGroup',
                            ],
                        },
                        siteWideRemoveLinksBookingPaths: false,
                        homePageRemoveFlightSearchTab: false,
                        hideWhoisGoingButton: false,
                        tourEnquiryContactUsPopout: false,
                        contactUsRemoveHeader: false,
                        tourPagePAndAHoldForFree: false,
                        tourSearchDatesOnResults: false,
                        siteWideSearchToolDefaultSpecificDate: false,
                        landingPagesLargerButtonToSRP: false,
                        searchResultsResultImageMultipleImages: false,
                        searchResultsSmallDesktopDisplay: false,
                        searchResultsSmallMobileDisplay: false,
                        landingPagesReturnToCheckoutToast: false,
                        soldOutDeparturesOnRequest: false,
                        removeGuaranteedRefundUsp: false,
                        tourPagePAndABookText: 0,
                        whoIsGoingHideForm: false,
                        tourPageKeyDetailsV2: false,
                        tourPagePandAAddFlightsButton: false,
                        tourPageItineraryV2: false,
                        siteWideEnableFlights: false,
                        siteWideEnableTradeBanner: false,
                        siteWideHeaderVersion: 1,
                        imageHandlerVersion: 1,
                        TourPageImageGalleryVersion: 1,
                    });
                })
                .finally(() => {
                    setIsRequesting(false);
                    setIsLoading(false);
                });
        }
    }, [isRequesting, features, experimentTrackingUUID, accessToken, bot]);

    return (
        <FeatureFlagContext.Provider
            value={{
                isLoading: isLoading,
                features: features,
            }}
        >
            {children}
        </FeatureFlagContext.Provider>
    );
}

function getFeatureValue<T extends string | boolean | number | OrderingFeature>(
    features: Feature[] | null,
    feature: FeatureFlag,
    defaultValue: T
): T {
    var result = features?.find(f => f.name === feature)?.value ?? null;
    if (result !== null) {
        try {
            const type = typeof defaultValue;
            switch (type) {
                case 'string':
                    return result as T;
                case 'number':
                    return Number(result) as T;
                case 'boolean': {
                    return result as T;
                }
                case 'object':
                    return result as T;
                default:
                    throw new Error(
                        `Unable to use feature flag ${feature} of type ${type}.`
                    );
            }
        } catch (error) {
            logErrorWithCustomMessage(error, 'Failed to parse feature flag');
            return defaultValue;
        }
    } else {
        return defaultValue;
    }
}

export default FeatureFlagContext;
